import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { QuillEditor } from '@vueup/vue-quill'

import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import './assets/css/style.css'

const app = createApp(App)

app.use(ElementPlus, { locale: zhCn })
app.component('QuillEditor', QuillEditor)
app.use(store)
app.use(router)
app.mount('#app')
