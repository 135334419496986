import { createLogger, createStore } from 'vuex'
import auth from './modules/auth'
import customer from './modules/customer'
import keep from './modules/keep'
import menu from './modules/menu'
import sales from './modules/sales'
import user from './modules/user'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        auth,
        customer,
        keep,
        menu,
        sales,
        user,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
})
