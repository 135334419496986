import { getMenuList } from '@/api/menu'

const state = () => ({
    menu: [],
})

const actions = {
    getMenu({ commit }) {
        return new Promise(resolve => {
            getMenuList()
                .then(resp => {
                    commit('setMenu', resp)

                    resolve()
                })
        })
    },
}

const mutations = {
    setMenu: (state, menu) => {
        state.menu = menu
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
