const state = () => ({
    include: [],
})

const actions = {
    add({ commit }, include) {
        commit('setInclude', include)
    },
}

const mutations = {
    setInclude: (state, include) => {
        state.include = include
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
