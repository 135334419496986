import { getSalesOptions } from '@/api/sales'

const state = () => ({
    salesList: [],
})

const actions = {
    getSalesOptions({ commit }) {
        return new Promise(resolve => {
            getSalesOptions()
                .then(resp => {
                    commit('setSalesList', resp)

                    resolve()
                })
        })
    },
}

const mutations = {
    setSalesList: (state, salesList) => {
        state.salesList = salesList
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
