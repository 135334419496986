import { login } from '@/api/auth'

const state = () => ({
    token: localStorage.getItem('token') || null,
})

const actions = {
    login({ commit }, credential) {
        return new Promise((resolve, reject) => {
            login(credential)
                .then(resp => {
                    const { token, user } = resp

                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(user))

                    commit('setToken', token)
                    commit('user/setUser', user, { root: true })

                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    logout({ commit }) {
        return new Promise(resolve => {
            localStorage.removeItem('token')
            localStorage.removeItem('user')

            commit('setToken', null)
            commit('menu/setMenu', [], { root: true })
            commit('user/setUser', null, { root: true })

            resolve()
        })
    },
}

const mutations = {
    setToken: (state, token) => {
        state.token = token
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
