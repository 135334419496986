import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'

const routes = [
    {
        path: '/',
        name: '首页',
        redirect: '/dashboard',
        component: () => import('@/views/Layout/AdminLayout.vue'),
        children: [
            {
                path: '/dashboard',
                name: '概览',
                component: () => import('@/views/Admin/Dashboard.vue'),
            },
            {
                path: '/report/customer',
                name: '客户统计',
                component: () => import('@/views/Admin/Report/Customer.vue'),
            },
            {
                path: '/report/product',
                name: '产品统计',
                component: () => import('@/views/Admin/Report/Product.vue'),
            },
            {
                path: '/report/sales',
                name: '销售人员业绩',
                component: () => import('@/views/Admin/Report/Sales.vue'),
            },
            {
                path: '/report/transaction',
                name: '交易统计',
                component: () => import('@/views/Admin/Report/Transaction.vue'),
            },
            {
                path: '/report/transaction/rate',
                name: '交易加价统计',
                component: () => import('@/views/Admin/Report/TransactionRate.vue'),
            },
            {
                path: '/report/balance',
                name: '余额统计',
                component: () => import('@/views/Admin/Report/Balance.vue'),
            },
            {
                path: '/report/profit',
                name: '盈亏统计',
                component: () => import('@/views/Admin/Report/Profit.vue'),
            },
            {
                path: '/card',
                name: '卡片查询',
                component: () => import('@/views/Admin/Card/CardIndex.vue'),
            },
            {
                path: '/card/create',
                name: '开卡',
                component: () => import('@/views/Admin/Card/Create.vue'),
            },
            {
                path: '/card/job',
                name: '开卡任务',
                component: () => import('@/views/Admin/Card/Job.vue'),
            },
            {
                path: '/card/cancel',
                name: '注销卡片管理',
                component: () => import('@/views/Admin/Card/Cancel.vue'),
            },
            {
                path: '/card/expired',
                name: '过期卡片管理',
                component: () => import('@/views/Admin/Card/Expired.vue'),
            },
            {
                path: '/card/review',
                name: '异常卡片管理',
                component: () => import('@/views/Admin/Card/Review.vue'),
            },
            {
                path: '/card/view/:token',
                name: '查看卡片详情',
                component: () => import('@/views/Admin/Card/View.vue'),
            },
            {
                path: '/customer',
                name: '客户查询',
                component: () => import('@/views/Admin/Customer/CustomerIndex.vue'),
            },
            {
                path: '/customer/create',
                name: '开户',
                component: () => import('@/views/Admin/Customer/Create.vue'),
            },
            {
                path: '/customer/view/:token',
                name: '客户详情',
                component: () => import('@/views/Admin/Customer/View.vue'),
            },
            {
                path: '/customer/edit/:token',
                name: '客户编辑',
                component: () => import('@/views/Admin/Customer/Edit.vue'),
            },
            {
                path: '/customer/price/:token',
                name: '客户价格编辑',
                component: () => import('@/views/Admin/Customer/Price.vue'),
            },
            {
                path: '/balance/record/recharge',
                name: '充值记录',
                component: () => import('@/views/Admin/Balance/Record/Recharge.vue'),
            },
            {
                path: '/balance',
                name: '客户余额',
                component: () => import('@/views/Admin/Balance/Index.vue'),
            },
            {
                path: '/balance/record',
                name: '余额明细',
                component: () => import('@/views/Admin/Balance/Record/Index.vue'),
            },
            {
                path: '/customer/currency/rule',
                name: '客户加价',
                component: () => import('@/views/Admin/Customer/CurrencyRule.vue'),
            },
            {
                path: '/authorization',
                name: '授权交易',
                component: () => import('@/views/Admin/Authorization.vue'),
            },
            {
                path: '/transaction',
                name: '结算交易',
                component: () => import('@/views/Admin/Transaction.vue'),
            },
            {
                path: '/exchange',
                name: '购汇',
                component: () => import('@/views/Admin/Exchange/Index.vue'),
            },
            {
                path: '/exchange/create',
                name: '创建购汇',
                component: () => import('@/views/Admin/Exchange/Create.vue'),
            },
            {
                path: '/exchange/view/:id',
                name: '购汇详情',
                component: () => import('@/views/Admin/Exchange/View.vue'),
            },
            {
                path: '/sales',
                name: '销售查询',
                component: () => import('@/views/Admin/Sales/Index.vue'),
            },
            {
                path: '/sales/create',
                name: '销售创建',
                component: () => import('@/views/Admin/Sales/Create.vue'),
            },
            {
                path: '/sales/edit/:token',
                name: '销售编辑',
                component: () => import('@/views/Admin/Sales/Edit.vue'),
            },
            {
                path: '/log',
                name: 'API日志',
                component: () => import('@/views/Admin/Log.vue'),
            },
            {
                path: '/user',
                name: '员工管理',
                component: () => import('@/views/Admin/User/Index.vue'),
            },
            {
                path: '/user/create',
                name: '员工创建',
                component: () => import('@/views/Admin/User/Create.vue'),
            },
            {
                path: '/user/edit/:token',
                name: '员工编辑',
                component: () => import('@/views/Admin/User/Edit.vue'),
            },
            {
                path: '/role',
                name: '角色管理',
                component: () => import('@/views/Admin/Role/Index.vue'),
            },
            {
                path: '/role/create',
                name: '角色创建',
                component: () => import('@/views/Admin/Role/Create.vue'),
            },
            {
                path: '/role/edit/:id',
                name: '角色编辑',
                component: () => import('@/views/Admin/Role/Edit.vue'),
            },
            {
                path: '/notice',
                name: '公告管理',
                component: () => import('@/views/Admin/Notice/Index.vue'),
            },
            {
                path: '/notice/create',
                name: '公告创建',
                component: () => import('@/views/Admin/Notice/Create.vue'),
            },
            {
                path: '/notice/view/:id',
                name: '公告详情',
                component: () => import('@/views/Admin/Notice/View.vue'),
            },
            {
                path: '/notice/edit/:id',
                name: '公告编辑',
                component: () => import('@/views/Admin/Notice/Edit.vue'),
            },
            {
                path: '/currency',
                name: '币种',
                component: () => import('@/views/Admin/Currency/Index.vue'),
            },
            {
                path: '/currency/rate',
                name: '汇率管理',
                component: () => import('@/views/Admin/Currency/Rate.vue'),
            },
            {
                path: '/currency/rule',
                name: '加价',
                component: () => import('@/views/Admin/Currency/Rule.vue'),
            },
            {
                path: '/product',
                name: '产品',
                component: () => import('@/views/Admin/Product/Index.vue'),
            },
            {
                path: '/product/create',
                name: '产品创建',
                component: () => import('@/views/Admin/Product/Create.vue'),
            },
            {
                path: '/product/edit/:token',
                name: '产品编辑',
                component: () => import('@/views/Admin/Product/Edit.vue'),
            },
            {
                path: '/profile',
                name: '个人中心',
                component: () => import('@/views/Admin/Profile.vue'),
            },
        ],
    },
    {
        path: '/',
        name: 'Auth',
        component: () => import('@/views/Layout/AuthLayout.vue'),
        children: [
            {
                path: '/register',
                name: '注册',
                component: () => import('@/views/Auth/Register.vue'),
            },
            {
                path: '/login',
                name: '登录',
                component: () => import('@/views/Auth/Login.vue'),
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
    NProgress.start()

    if (!['/register', '/login'].includes(to.path) && !store.state.auth.token) {
        next({ name: '登录' })
    } else {
        if (from.name === '卡片查询' && to.name === '查看卡片详情') {
            store.dispatch('keep/add', ['CardIndex']).then(() => next())
        } else if (from.name === '客户查询' && to.name === '客户详情') {
            store.dispatch('keep/add', ['CustomerIndex']).then(() => next())
        } else if (!['卡片查询', '客户查询'].includes(to.name)) {
            store.dispatch('keep/add', []).then(() => next())
        } else {
            next()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})

export default router
