const state = () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
})

const actions = {
}

const mutations = {
    setUser: (state, user) => {
        state.user = user
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
