import request from '@/utils/request'

export function createSales(data) {
    return request({
        url: '/sales',
        method: 'post',
        data,
    })
}

export function getSales(token) {
    return request({
        url: `/sales/${token}`,
        method: 'get',
    })
}

export function getSalesList(params) {
    return request({
        url: '/sales',
        method: 'get',
        params,
    })
}

export function getSalesOptions() {
    return request({
        url: '/sales/option',
        method: 'get',
    })
}

export function updateSales(data) {
    return request({
        url: `/sales/${data.token}`,
        method: 'put',
        data,
    })
}

export function updateSalesNote(data) {
    return request({
        url: `/sales/note/${data.token}`,
        method: 'post',
        data,
    })
}

export function updateSalesStatus(data) {
    return request({
        url: `/sales/status/${data.token}`,
        method: 'post',
        data,
    })
}
